import React from "react";

function LineCaretUp() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M11.293 8.793a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L12 10.914l-4.293 4.293a1 1 0 01-1.414-1.414l5-5z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default LineCaretUp;
