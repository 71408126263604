const OutlineCaretUp = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M5.161 14.908c-.707.809-.133 2.073.94 2.073h11.797c1.074 0 1.648-1.265.94-2.073l-5.521-6.31a1.75 1.75 0 00-2.634 0l-5.522 6.31zm1.492.573l5.159-5.896a.25.25 0 01.376 0l5.16 5.896H6.652z"
            ></path>
        </svg>
    );
};

export default OutlineCaretUp;
