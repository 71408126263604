const OutlineCaretDown = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M5.161 10.073C4.454 9.265 5.028 8 6.101 8h11.797c1.074 0 1.648 1.265.94 2.073l-5.521 6.31a1.75 1.75 0 01-2.634 0l-5.522-6.31zM6.653 9.5l5.159 5.896a.25.25 0 00.376 0l5.16-5.896H6.652z"
            ></path>
        </svg>
    );
};

export default OutlineCaretDown;
