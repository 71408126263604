import { IconsLookup } from "@lib/shared/types";
import FilledCaretDown from "./icons/FilledCaretDown";
import FilledCaretUp from "./icons/FilledCaretUp";
import LineCaretDown from "./icons/LineCaretDown";
import LineCaretUp from "./icons/LineCaretUp";
import MinusIcon from "./icons/MinusIcon";
import OutlineCaretDown from "./icons/OutlineCaretDown";
import OutlineCaretUp from "./icons/OutlineCaretUp";
import PlusIcon from "./icons/PlusIcon";

export const iconsLookup: IconsLookup = {
    filled: {
        up: <FilledCaretUp />,
        down: <FilledCaretDown />,
    },
    outline: {
        up: <OutlineCaretUp />,
        down: <OutlineCaretDown />,
    },
    lines: {
        up: <LineCaretUp />,
        down: <LineCaretDown />,
    },
    plusMinus: {
        up: <MinusIcon />,
        down: <PlusIcon />,
    },
};
